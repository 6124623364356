import { OffsetPaginationOptions } from '../common/pagination';

export class LoadServices {
  static readonly type = '[Services] Load Services';
}

export class LoadServiceAction {
  static readonly type = '[Services] Load Service';
  constructor(public id: number) {}
}

export class LoadMonitorsForServiceAction {
  static readonly type = '[Services] Load Monitors for Service';
  constructor(
    public id: number,
    public pagination: OffsetPaginationOptions
  ) {}
}

export class LoadActiveIncidentsForServiceAction {
  static readonly type = '[Services] Load Active Incidents';
  constructor(
    public id: number,
    public pagination: OffsetPaginationOptions
  ) {}
}

export class LoadRecentIncidentsForServiceAction {
  static readonly type = '[Services] Load Recent Incidents';
  constructor(
    public id: number,
    public pagination: OffsetPaginationOptions
  ) {}
}

export class UpdateServiceAction {
  static readonly type = '[Services] Update Service';
  constructor(
    public serviceId: number,
    public updateSpec: {
      name?: string;
      description?: string;
      ownerTeamPublicId?: number;
    }
  ) {}
}

export class DeleteServiceAction {
  static readonly type = '[Services] Delete Service';
  constructor(public serviceId: number) {}
}

export class LoadServiceStatisticsAction {
  static readonly type = '[Services] Load Service Statistics';
  constructor(
    public serviceId: number,
    public windowStart: string,
    public windowEnd: string
  ) {}
}
